export var SearchList = [
  { labe: '采购品类', code: 'name', type: 'input', placeholder: '编码/名称' }

]
export var SearchData = {
  name: ''
}

export var tableField = [
  { label: '采购品类代码', code: 'value', type: 'input', width: '40%' },
  { label: '采购品类名称', code: 'label', type: 'input', width: '' }
]

export var tableData = [
  { label: '主板、板卡', value: 'PL001' },
  { label: 'OEM整机、防火墙等', value: 'PL002' },
  { label: '硬盘', value: 'PL003' },
  { label: 'SSD', value: 'PL004' },
  { label: '盘阵', value: 'PL005' },
  { label: '光纤HBA卡', value: 'PL006' },
  { label: '光纤模块', value: 'PL007' },
  { label: '外设', value: 'PL008' },
  { label: '自制品', value: 'PL009' },
  { label: '网卡', value: 'PL010' },
  { label: '线缆', value: 'PL011' },
  { label: 'CPU', value: 'PL012' },
  { label: 'GPU、显卡', value: 'PL013' },
  { label: '结构件、附件', value: 'PL014' },
  { label: '内存', value: 'PL015' },
  { label: '背板', value: 'PL016' },
  { label: '中板', value: 'PL017' },
  { label: '交换机', value: 'PL018' },
  { label: 'RAID卡', value: 'PL019' },
  { label: '电源', value: 'PL020' },
  { label: '风扇', value: 'PL021' },
  { label: '散热器', value: 'PL022' },
  { label: '包材', value: 'PL023' },
  { label: '标签', value: 'PL024' },
  { label: '资材类（固资、费用、礼品、印刷品等）', value: 'PL025' },
  { label: '外购软件', value: 'PL026' },
  { label: '服务', value: 'PL027' },
  { label: '商品', value: 'PL028' },
  { label: '第三方采购物料', value: 'PL029' },
  { label: '研发购原材料', value: 'PL030' },
  { label: '仙络原材料', value: 'XL001' }
]
