<template>
    <div class="tableListData">
      <!-- <list-search :childData="childData" ></list-search> -->
      <global-table ref="cateNew" highlight-current-row @current-change="handleCurrentChange" :tableField="tableField" :tableData="tableData" ></global-table>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { tableField, tableData, SearchList, SearchData } from './ProcurementCategory'
export default {
  components: Component.components,
  name: 'ProcurementCategory',
  data () {
    return {
      currentChangeData: [],
      childData: { searchList: SearchList, searchData: SearchData },
      tableField: tableField,
      tableData: tableData
    }
  },
  methods: {
    handleCurrentChange (val) {
      this.currentChangeData = val
    }
  }
}
</script>
<style scoped lang="scss">
.tableListData{
  .tableNav {
    border-bottom:none;
  }
}
</style>
